import _objectSpread from "/var/www/git/antares-web-frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { computed, h, ref, watch, nextTick } from "vue";
import { RouterLink as OriginalRouterLink, useRoute } from "vue-router";
import { translateRoute } from "@/helpers/language.js";
export default {
  props: OriginalRouterLink.props,
  setup: function setup(__props) {
    var props = __props;
    var route = useRoute();
    var refreshTrigger = ref(false); // Used to force links to update; links using route name tend to get stuck

    /**
     * Adjust router-link props as necessary for Language Sub Directory
     */

    var LinkProps = computed(function () {
      var to = null;

      try {
        var _route$meta;

        to = translateRoute(props.to, (_route$meta = route.meta) === null || _route$meta === void 0 ? void 0 : _route$meta.lang);
      } catch (e) {
        console.error(e);
        return props;
      }

      if (!to) {
        return props;
      }

      return _objectSpread(_objectSpread({}, props), {}, {
        to: to
      });
    });
    /**
     * Foward modified props to original router-link
     */

    var Link = computed(function () {
      if (!LinkProps.value) return null;
      return h(OriginalRouterLink, LinkProps.value);
    });
    /**
     * Force the link to refresh by removing and re-inserting on the next tick
     */

    watch(LinkProps, function (newVal, oldVal) {
      var _newVal$to$params, _oldVal$to$params;

      if (((_newVal$to$params = newVal.to.params) === null || _newVal$to$params === void 0 ? void 0 : _newVal$to$params.lang) !== ((_oldVal$to$params = oldVal.to.params) === null || _oldVal$to$params === void 0 ? void 0 : _oldVal$to$params.lang)) {
        refreshTrigger.value = true;
        nextTick(function () {
          return refreshTrigger.value = false;
        });
      }
    });
    return function (_ctx, _cache) {
      return !refreshTrigger.value ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(Link)), {
        key: 0
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      })) : _createCommentVNode("", true);
    };
  }
};